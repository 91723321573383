.icon {
  padding-block-end: 4px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.iconButton {
  &:hover,
  &:focus {
    img {
      filter: brightness(0) invert(1);
    }
  }
  &:focus{
    background-color: #357c00 !important;
    color: white !important;
  }
  height: 80px;
  width: 140px;
  font-size: 14px !important;
  line-height: 21px;
  text-align: center;
}

.buttonContainer {
  gap: 14px;
}
